export const faqData = [
    {
      question: 'What is EdSpare?',
      answer: 'EdSpare is an AI-powered SaaS platform designed for educational institutions and teachers. It offers various tools and features to enhance teaching and learning experiences.',
    },
    {
      question: 'What services does EdSpare provide?',
      answer: 'EdSpare provides services such as intelligent tutoring, personalized learning paths, automated grading and assessment, student performance analytics, and content recommendation based on AI algorithms.',
    },
    {
      question: 'How does EdSpare use AI in education?',
      answer: 'EdSpare leverages AI algorithms to analyze student data, identify learning patterns, and deliver personalized content and recommendations. It also utilizes natural language processing for intelligent tutoring and automated grading.',
    },
    {
      question: 'Is EdSpare suitable for all educational levels?',
      answer: 'Yes, EdSpare is designed to cater to educational institutions across various levels, including K-12 schools, colleges, and universities. The platform can be customized to meet the specific needs of different educational settings.',
    },
    {
      question: 'What are the benefits of using EdSpare?',
      answer: 'Using EdSpare offers benefits such as improved student engagement, personalized learning experiences, automated grading and assessment, data-driven insights for teachers, and optimized educational content delivery.',
    },
    {
      question: 'How secure is the data on EdSpare?',
      answer: 'EdSpare takes data security seriously and employs industry-standard security measures to protect user data. It ensures compliance with data protection regulations and implements encryption, secure authentication, and regular data backups.',
    },
    {
      question: 'Can EdSpare integrate with other educational tools and systems?',
      answer: 'Yes, EdSpare offers integration capabilities with other educational tools and systems, such as learning management systems (LMS), student information systems (SIS), and online content providers, to streamline workflows and enhance the overall educational ecosystem.',
    },
  ];
  