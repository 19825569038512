import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import '.././LandingPage.css'

const Welcome = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[400px] md:h-[600px] welcome-background">
      <div className="flex justify-center items-center text-sm gap-3 w-5/6 md:w-full">
        <p className="text-[#0E0B3D] font-bold">What's New</p>
        <span>|</span>
        <p className="text-[#536B79] flex items-center gap-2 cursor-pointer">
          <span>Our Vision for EdSpare</span>
          <BiRightArrowAlt className="text-sm" />
        </p>
      </div>
      <div className="w-5/6 md:w-1/2 m-2 p-2">
        <h1 className="text-center">
          Learning Made{" "}
          <span className="bg-[#F5BF45]">Simple.</span>
        </h1>
        <p className="leading-8 text-xl font-bold text-center">
          We teach in your language.
        </p>
      </div>
      <div className="flex gap-6 items-center">
        <button className="bg-[#130A46] px-7 py-2 rounded-full text-white flex items-center gap-2">
          <span>Join Us</span>
        </button>
        <button className="flex items-center gap-2 bg-transparent underline">
          <span>See How It Works</span>
          <BiRightArrowAlt />
        </button>
      </div>
    </div>
  );
};

export default Welcome;
