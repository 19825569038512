import React from "react";
import Welcome from "./components/Welcome/Welcome";
import VideoSection from "./components/Welcome/VideoSection";
import MatchMaking from "./components/InfoSections/MatchMaking";
import Logos from "./components/InfoSections/Logos";
import DataAnalytics from "./components/InfoSections/DataAnalytics";
import LiveEvents from "./components/InfoSections/LiveEvents";
import OurProducts from "./components/Products/OurProducts";
import TestimonialCEO from "./components/Team/TestimonialCEO";
import EdspareFeatures from "./components/InfoSections/EdspareFeatures";
import OurTeam from "./components/Team/OurTeam";
import Testimonials from "./components/Testimonials/Testimonials";
import BlogSection from "./components/BlogSection/BlogSection";
import posterImage from "../../assets/images/Frame 10336.png";
import Faqs from "./components/FAQs/Faqs";
import ContactUs from "./components/ContactUs/ContactUs";
import Layout from "../../layout/Layout";

const videoUrl = "./";

const LandingPage = () => {
  return (
    <div>
      <Layout>
        <Welcome />
        <VideoSection videoUrl={videoUrl} posterImage={posterImage} />
        <Logos />
        <MatchMaking />
        <DataAnalytics />
        <LiveEvents />
        <OurProducts />
        <TestimonialCEO />
        <EdspareFeatures />
        <OurTeam />
        <Testimonials />
        <BlogSection />
        <Faqs />
        <ContactUs />
      </Layout>
    </div>
  );
};

export default LandingPage;
