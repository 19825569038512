import React from "react";
import ProductCard from "./ProductCard";
import tab1 from "../../../../assets/images/tab1.jpg";
import tab2 from "../../../../assets/images/tab2.jpg";
import tab3 from "../../../../assets/images/tab3.jpg";
import tab4 from "../../../../assets/images/tab4.jpg";
import BtnSection from "../../../../components/Buttons/BtnSection";
import { Link } from "react-router-dom";

const imgUrl = [tab1, tab2, tab3, tab4];

const OurProducts = () => {
  return (
    <div className="flex flex-col justify-center items-center py-6">
      <h3 className="pt-4 pb-8">Our Products</h3>
      <br />
      <div className="w-3/5 grid grid-cols-1 md:grid-cols-4 gap-6">
        {imgUrl.map((img, index) => (
          <ProductCard key={index} imgUrl={img} />
        ))}
      </div>
      <br />
      <Link to='/shop'>
        <BtnSection text={"Shop Now"} />
      </Link>
    </div>
  );
};

export default OurProducts;
