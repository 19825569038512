import React from "react";
import Layout from "../../layout/Layout";
import { AiFillMail } from "react-icons/ai";
import { BsFillTelephoneFill, BsPhone } from "react-icons/bs";
import Form from "./components/Form";
import './ContactUs.css'

const ContactUs = () => {
  return (
    <Layout>
      <div className="md:flex justify-center contact-background bg-[#FCFBF8]">
        <div className="p-8 m-4 text-[#0E0B3D] md:w-1/2">
          <h5>Contact Us</h5>
          <h3>Get in touch</h3>
          <br />
          <p className="md:w-3/5">
            Lorem ipsum dolor sit amet consectetur. Donec porttitor mattis
            integer viverra. Tellus in ut malesuada dictum id ultrices senectus
            ultrices nam. Quisque arcu at metus pellentesque. Gravida risus cras
            congue egestas viverra maecenas morbi.
          </p>
          <br />
          <div className="flex flex-col gap-3 mt-4">
            <span className="flex items-center gap-4">
              <AiFillMail /> info@edspare.com
            </span>
            <span className="flex items-center gap-4">
              <BsFillTelephoneFill /> 02086746297
            </span>
            <span className="flex items-center gap-4">
              <BsPhone /> +44 7946 087069
            </span>
            <span className="flex items-center gap-4">
              <BsPhone /> +234-8023-2396-33
            </span>
          </div>
        </div>
        <div className="p-8">
          <Form />
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
