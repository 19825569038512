import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import './BlogPage.css'

const BlogCard = ({ blog }) => {
  return (
    <div className="blog-card">
      <img src={blog.blogImageUrl} alt="blogImage" width={400} />
      <div className="p-6">
        <h4>{blog.blogTitle}</h4>
        <div className="flex gap-2 my-4">
          {blog.blogTags.map((tag, index) => (
            <span key={index} className="bg-[#FFDA87] rounded-full py-2 px-3 text-xs">
              {tag}
            </span>
          ))}
        </div>
        <p className="text-sm my-4">{blog.blogDescription}</p>
        <span className="text-[#969696] text-xs">
          {blog.blogDate} • {blog.blogLastUpdated}
        </span>
        <br />
        <button className="flex items-center text-xs gap-2 my-3 text-[#0E0B3D] font-semibold">
          Read More
          <BsArrowUpRight />
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
