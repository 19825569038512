import React from "react";
import ".././LandingPage.css";

const VideoSection = ({ posterImage }) => {
  return (
    <div>
      <div className="flex justify-center items-center">
        {/**
          <video className="w-5/6 md:w-3/5 border-2 border-solid border-slate-400 rounded-lg cursor-pointer m-2" src='https://youtu.be/IWmwM8odqKU' poster={posterImage} />
         */}

        <iframe
          className="md:w-3/5 md:h-[535px] w-5/6 h-[195px]"
          src="https://www.youtube.com/embed/IWmwM8odqKU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoSection;
