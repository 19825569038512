export const testimonialData = [
  {
    id: 1,
    testimonial: "Edspare elevated learning for our students! The intuitive design, interactive features, and personalized experience made a significant impact. Proud Edspare partner!",
    imgUrl: require("../../../../assets/images/testimonial1.png"),
    name: "Gregory Hawkins",
    designation: "Marketing Coordinator",
  },
  {
    id: 2,
    testimonial: "Edspare is a game-changer! Their platform streamlined our online courses, improved student engagement, and made learning a breeze. Highly recommended!",
    imgUrl: require("../../../../assets/images/testimonial2.png"),
    name: "Carla James",
    designation: "School Coordinator",
  },
  {
    id: 3,
testimonial: "Edspare is amazing! Their user-friendly platform and powerful tools made course management a breeze. Couldn't imagine teaching without it!",
    imgUrl: require("../../../../assets/images/testimonial3.png"),
    name: "James Anderson",
    designation: "Vice Principal",
  },
  {
    id: 4,
    testimonial: "Edspare empowered our edutech startup! Their cost-effective solution helped us scale, reach more students, and build engaging courses. Highly recommended!",
    imgUrl: require("../../../../assets/images/testimonial4.png"),
    name: "Monica Hershey",
    designation: "Mathematics Teacher",
  },
];
