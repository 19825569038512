export const blogData = [
  {
    id: 1,
    imgUrl: require("../../../../assets/images/blog1.png"),
    blogTitle: "How to create a contact form with Edspare",
    blogDescription:
      "Lorem ipsum dolor sit amet consectetur. Sed mattis  pharetra quis metus. Risus vel lectus velit purus id. Pretium at sed ultricies placerat et faucibus. Pellentesque bibendum elementum sit gravida. Tristique pretium fringilla id vulputate.",
  },
  {
    id: 2,
    imgUrl: require("../../../../assets/images/blog2.png"),
    blogTitle: "How to create a contact form with Edspare",
    blogDescription:
      "Lorem ipsum dolor sit amet consectetur. Sed mattis  pharetra quis metus. Risus vel lectus velit purus id. Pretium at sed ultricies placerat et faucibus. Pellentesque bibendum elementum sit gravida. Tristique pretium fringilla id vulputate.",
  },
  {
    id: 3,
    imgUrl: require("../../../../assets/images/blog3.png"),
    blogTitle: "How to create a contact form with Edspare",
    blogDescription:
      "Lorem ipsum dolor sit amet consectetur. Sed mattis  pharetra quis metus. Risus vel lectus velit purus id. Pretium at sed ultricies placerat et faucibus. Pellentesque bibendum elementum sit gravida. Tristique pretium fringilla id vulputate.",
  },
];
