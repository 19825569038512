import React, { useState } from "react";
import { productData } from "./ProductData";
import ProductCard from "./ProductCard";
import { AiFillCaretDown } from 'react-icons/ai'

const Products = () => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="md:w-3/4 w-full mx-6">
      <div className="py-2 flex justify-between">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          className="bg-[#fffbf2] py-2 px-4 rounded md:w-2/5 w-3/5 border-2 border-solid border-yellow-200"
        />
        <div className="flex">
          <button className="flex items-center gap-2 bg-yellow-200 px-3 rounded text-sm">
            <span>Sort By</span>
            <AiFillCaretDown />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {productData.map((product, index) => (
          <ProductCard
            key={index}
            productName={product.productName}
            productImage={product.productImage}
            productPrice={product.productPrice}
            productDescription={product.productDescription}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
