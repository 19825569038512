import React, { useState } from "react";
import edspareLogo from "../../assets/images/edspare-logo.png";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import Dropdown from "./Dropdown";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const aboutDropdown = [
    {
      link: "/our-team",
      linkName: "Team",
    },
    {
      link: "/mission-vision",
      linkName: "Mission & Vision",
    },
    {
      link: "/careers",
      linkName: "Careers at EdSpare",
    },
  ];

  const productDropdown = [
    {
      link: "/edspare-school",
      linkName: "EdSpare School",
    },
    {
      link: "/edspare-eep-sep",
      linkName: "EdSpare EEP & SEP",
    },
    {
      link: "/edspare-student",
      linkName: "EdSpare Student",
    },
    {
      link: "/our-application",
      linkName: "Our Application",
    },
  ];

  const servicesDropdown = [
    {
      link: "/ai-teaching-model",
      linkName: "AI Based Teaching Model",
    },
    {
      link: "/student-teacher-networking",
      linkName: "Student & Teacher Network",
    },
    {
      link: "/our-library",
      linkName: "Our Library",
    },
    {
      link: "/panic-button",
      linkName: "Panic Button",
    },
    {
      link: "/our-schools",
      linkName: "Our Schools",
    },
    {
      link: "/tutoring-facilities",
      linkName: "One-to-one Tutoring",
    },
  ];

  return (
    <nav className="flex justify-center items-center">
      <div className="flex justify-between w-5/6">
        <Link to="/">
          <img
            src={edspareLogo}
            width={150}
            height={50}
            className="mt-3"
            alt=""
          />
        </Link>
        <ul className="md:flex hidden pt-6 text-sm gap-4 text-[#535479]">
          <li>
            <Link to="/shop">Shop</Link>
          </li>
          <li>
            <Dropdown linkName="About EdSpare" dropdownList={aboutDropdown} />
          </li>
          <li>
            <Dropdown linkName="Products" dropdownList={productDropdown} />
          </li>
          <li>
            <Dropdown linkName="Services" dropdownList={servicesDropdown} />
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
        <div className="pt-3 text-sm md:flex hidden">
          <button className="px-3 py-1 m-1 bg-transparent flex items-center gap-1">
            <Link to="/login">
              <span>Login</span>
            </Link>
            <BiRightArrowAlt />
          </button>
          <button className="px-6 m-1 rounded-full bg-[#F7F9FC] hover:bg-[#130A46] hover:text-white">
            <Link to="/contact-us">Contact Us</Link>
          </button>
        </div>
      </div>
      <div className="flex relative">
        {!toggleMenu && (
          <HiMenuAlt4
            fontSize={28}
            className="md:hidden cursor-pointer"
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <AiOutlineClose
            fontSize={28}
            className="md:hidden cursor-pointer"
            onClick={() => setToggleMenu(false)}
          />
        )}
        {toggleMenu && (
          <ul
            className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
            flex flex-col justify-start items-start rounded-md bg-[#fffbf2] animate-slide-in"
          >
            <li className="text-xl w-full my-2">
              <AiOutlineClose onClick={() => setToggleMenu(false)} />
            </li>
            <ul className="flex flex-col px-3 pt-6 text-md gap-4 text-[#535479]">
              <li>Shop</li>
              <li>
                <Dropdown
                  linkName="About EdSpare"
                  dropdownList={aboutDropdown}
                />
              </li>
              <li>
                <Dropdown linkName="Products" dropdownList={productDropdown} />
              </li>
              <li>
                <Dropdown linkName="Services" dropdownList={servicesDropdown} />
              </li>
              <li>Blog</li>
            </ul>
            <div className="flex pt-3">
              <button className="px-3 py-1 m-1 bg-transparent flex items-center gap-1">
                <Link to="/login">
                  <span>Login</span>
                </Link>
                <BiRightArrowAlt />
              </button>
              <button className="px-6 m-1 rounded-full bg-[#F7F9FC] hover:bg-[#130A46] hover:text-white">
                Contact Us
              </button>
            </div>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
