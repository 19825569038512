import React from "react";
import altImg from "../../../../assets/images/globe.svg";
import BtnSection from "../../../../components/Buttons/BtnSection";

const LiveEvents = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="md:flex gap-12 w-3/4 m-4 py-6 md:py-16">
        <div className="w-full md:w-1/2">
          <h3>Promoting Mental Health and Well-being</h3>
          <br />
          <p className="w-full md:w-3/4">
            At Edspare, we recognize the significant impact of mental health on
            student well-being and academic success. Our AI-based Saas platform
            goes beyond traditional educational solutions by prioritizing the
            mental health needs of students.
          </p>
          <br />
          <p className="w-full md:w-3/4">
            Our platform equips educators with the tools to identify early signs
            of distress, monitor student well-being, and provide timely
            interventions to support mental health and emotional well-being.
          </p>
          <br />
          <p className="w-full md:w-3/4">
            Our platform offers a range of resources, including interactive
            modules, mindfulness exercises, and stress management techniques, to
            help students build resilience, cope with challenges, and enhance
            their emotional intelligence.
          </p>
          <br />
          <BtnSection text={"Learn More"} />
        </div>
        <div className="w-full md:w-1/2 hidden md:block">
          <img src={altImg} alt="none" width={436} />
        </div>
      </div>
    </div>
  );
};

export default LiveEvents;
