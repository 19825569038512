import React from "react";
import altImg from "../../../../assets/images/matchmaking.svg";
import BtnSection from "../../../../components/Buttons/BtnSection";

const MatchMaking = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="md:flex gap-12 w-3/4 m-4 py-6 md:py-16">
        <div className="w-full md:w-1/2">
          <h3 className="w-full md:w-3/4">Personalized Learning with AI</h3>
          <br />
          <p className="py-2 w-full md:w-3/4">
            At Edspare, we are committed to revolutionizing the way students
            learn with our AI-powered personalized learning platform. We analyze
            individual student data, including their strengths, weaknesses, and
            learning preferences, to deliver personalized content and
            recommendations.
          </p>
          <br />
          <p className="py-2 w-full md:w-3/4">
            Our AI technology adapts to their progress, constantly adjusting the
            learning path to ensure maximum effectiveness and optimize their
            educational journey.
          </p>
          <br />
          <BtnSection text={"Learn More"} />
        </div>
        <div className="w-full md:w-1/2 md:pl-24 hidden md:block">
          <img src={altImg} alt="none" width={436} />
        </div>
      </div>
    </div>
  );
};

export default MatchMaking;
