import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ContactUs.css";

const Form = () => {
  const [countries, setCountries] = useState([]);

  const countryNames = [];

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => setCountries(res.data))
      .catch((err) => console.log(err));
  }, []);

  countries.map((country) => countryNames.push(country.name.common));

  countryNames.sort();

  return (
    <div>
      <form className="flex flex-col bg-white rounded-lg form-background p-6 m-4">
        <label>Full Name</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="John Doe"
        />
        <label>Organization Name</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="St. James School"
        />
        <label>Email</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="email"
          placeholder="johndoe@stjameschool.org"
        />
        <label>Country</label>
        <select className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]">
          {countryNames.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <label>Contact Number</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="number"
          placeholder="+44 7666 111199"
        />
        <label>Strength of Organization</label>
        <select className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]">
          <option value="<500">100-500</option>
          <option value="<5000">500-5000</option>
          <option value=">5000">5000+</option>
        </select>
        <label>How did you hear about us?</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="E.g. Google, LinkedIn, Facebook"
        />
        <label>Message</label>
        <textarea className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"></textarea>
        <button
          className="bg-[#F5BF45] p-2 font-semibold rounded my-2"
          type="submit"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default Form;
