import React from "react";
import './Testimonials.css'

const TestimonialCard = ({testimonial, imgUrl, name, designation}) => {
  return (
    <div className="testimonial-card flex flex-col justify-between m-2 p-2">
      <p className="testimonial-text p-4 truncate-2">
        {testimonial}
      </p>
      <div className="flex items-center gap-6 p-4">
        <img src={imgUrl} alt="" width={74} />
        <div>
          <h5>{name}</h5>
          <p className="tracking-wide text-sm">{designation}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
