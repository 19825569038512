import React from 'react'
import Layout from '../../layout/Layout'
import ShopLayout from './components/ShopLayout/ShopLayout'

const Shop = () => {
  return (
    <div>
        <Layout>
            <ShopLayout />
        </Layout>
    </div>
  )
}

export default Shop