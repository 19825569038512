import React from "react";
import {
  FcApproval,
  FcBiotech,
  FcBinoculars,
  FcBusinessman,
  FcClock,
  FcDataProtection,
  FcIdea,
} from "react-icons/fc";

const VerticalSlider = () => {
  return (
    <div>
      <div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcApproval />
            <h4>Personalized AI learning for individual student needs.</h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcBiotech />
            <h4>Real-time progress tracking for informed decision-making.</h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcBinoculars />
            <h4>Collaborative virtual classrooms for interactive learning.</h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcBusinessman />
            <h4>Focus on student mental health and well-being for students.</h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcClock />
            <h4>
              Secure platform for data privacy and protection for all users.
            </h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcDataProtection />
            <h4>Flexible and unlimited storage with EdSpare services.</h4>
          </div>
        </div>
        <div className="bg-white border-1 border-black p-4 m-4 rounded-lg shadow-md">
          <div className="flex items-center gap-4">
            <FcIdea />
            <h4>
              EdSpare is home to great ideas, bringing life to the
              impossible.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
