import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamCard from "./TeamCard";
import img1 from "../../../../assets/images/Frame 10367.png";
import img2 from "../../../../assets/images/Frame 10367 (1).png";
import img3 from "../../../../assets/images/Frame 10367 (2).png";
import img4 from "../../../../assets/images/WhatsApp Image 2023-03-03 at 22.41.52 (1).jpeg";
import img5 from "../../../../assets/images/WhatsApp Image 2023-07-02 at 22.06.50.jpg";
import img6 from "../../../../assets/images/Frame 1.png";

const teamData = [
  {
    id: 1,
    name: "Garry O. Sylvester",
    position: "Chief Executive Officer",
    imgUrl: img1,
  },
  {
    id: 2,
    name: "Ms Barbara T. Odisi",
    position: "Chief Operating Officer",
    imgUrl: img2,
  },
  {
    id: 3,
    name: "Muritala D.Y",
    position: "Vice President",
    imgUrl: img3,
  },
  {
    id: 4,
    name: "Daniyal Sohail",
    position: "Software Engineer",
    imgUrl: img4,
  },
  {
    id: 5,
    name: "Niraj Patel",
    position: "Front End Engineer",
    imgUrl: img5,
  },
  {
    id: 6,
    name: "Kusal Chathuranga",
    position: "Product Designer",
    imgUrl: img6,
  },
];

const OurTeam = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <br />
      <h3>Our Team</h3>
      <Carousel
        responsive={responsive}
        draggable={true}
        containerClass="carousel-container"
        arrows={true}
        className="w-3/5 m-6"
      >
        {teamData.map((team, index) => (
          <TeamCard
            imgUrl={team.imgUrl}
            personName={team.name}
            position={team.position}
            key={index}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default OurTeam;
