import React from "react";

const BtnSection = ({text}) => {
  return (
    <button className="border border-2 border-black rounded-md py-3 px-8 font-bold text-sm hover:shadow-lg">
      {text}
    </button>
  );
};

export default BtnSection;
