export const cookieData = [
    {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
      {
        heading: "1. We respect your privacy",
        paragraph:
          "Lorem ipsum dolor sit amet consectetur. Eget vivamus sagittis feugiat mauris faucibus orci vel porta. Tellus a pellentesque cursus amet quam. Mauris est bibendum et diam pellentesque nullam malesuada sollicitudin aenean. Accumsan nunc urna nulla nisl eu quis sit lectus. Ullamcorper vitae ipsum dictumst nullam. A pulvinar cursus eu tincidunt risus amet metus. Molestie quisque diam venenatis vel ac integer pulvinar turpis id. Lorem ipsum dolor sit amet consectetur. Cras quis felis risus magna. Luctus aenean quisque morbi purus adipiscing libero in est. Morbi nunc id.",
      },
]