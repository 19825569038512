import React from "react";
import { blogData } from "./BlogData";
import BlogCard from "./BlogCard";
import { BiChevronRight } from "react-icons/bi";

const BlogSection = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <h3>Useful to read</h3>
      <div className="md:flex md:w-3/4 w-5/6 gap-6 py-12">
        {blogData.map((blog, index) => (
          <BlogCard
            key={index}
            imgUrl={blog.imgUrl}
            blogTitle={blog.blogTitle}
            blogDescription={blog.blogDescription}
          />
        ))}
      </div>
      <button className="flex items-center gap-1 text-sm font-semibold">
        See more blog posts{" "}
        <span>
          <BiChevronRight className="text-xl" />
        </span>
      </button>
    </div>
  );
};

export default BlogSection;
