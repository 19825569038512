import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "./pages/login/Login";
import LandingPage from "./pages/landing-page/LandingPage";
import Shop from "./pages/shop/Shop";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsCondition from "./pages/terms-condition/TermsCondition";
import CookiePolicy from "./pages/cookie-policy/CookiePolicy";
import BlogPage from "./pages/blog/BlogPage";
import ContactUs from "./pages/contact-us/ContactUs";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  console.log(isLoggedIn);

  return (
    <Routes style={{ fontFamily: 'Faktum' }}>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route path="/contact-us" element={<ContactUs />} />
      {isLoggedIn && (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
        </>
      )}
    </Routes>
  );
}

export default App;
