import React, { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import "./Sidebar.css";

const Sidebar = () => {
  // Sample data for filter options
  const [isPriceCollapsed, setIsPriceCollapsed] = useState(false);
  const [isAccessoriesCollapsed, setIsAccessoriesCollapsed] = useState(false);
  const [isReleaseYearsCollapse, setIsReleasedYearCollapse] = useState(false);
  const [isScreenSizesCollapse, setIsScreenSizesCollapse] = useState(false);
  const [isStorageOptionsCollapse, setIsStorageOptionCollapse] =
    useState(false);
  const [isColorsCollapse, setIsColorsCollapse] = useState(false);

  const prices = ["Under $100", "$100 - $200", "$200 - $500", "$500 and above"];
  const accessories = ["Headphones", "Phone cases", "Chargers"];
  const releaseYears = ["2020", "2019", "2018", "2017 and earlier"];
  const screenSizes = ["Small", "Medium", "Large"];
  const storageOptions = ["16GB", "32GB", "64GB", "128GB"];
  const colors = ["Black", "White", "Silver", "Gold"];

  const handleTogglePrice = () => {
    setIsPriceCollapsed(!isPriceCollapsed);
  };

  const handleToggleAccessories = () => {
    setIsAccessoriesCollapsed(!isAccessoriesCollapsed);
  };

  const handleToggleReleaseYear = () => {
    setIsReleasedYearCollapse(!isReleaseYearsCollapse);
  };

  const handleToggleScreenSizes = () => {
    setIsScreenSizesCollapse(!isScreenSizesCollapse);
  };

  const handleToggleStorageOptions = () => {
    setIsStorageOptionCollapse(!isStorageOptionsCollapse);
  };

  const handleToggleColors = () => {
    setIsColorsCollapse(!isColorsCollapse);
  };

  return (
    <div className="main-container p-4 hidden md:block">
      <h4 className="text-lg font-bold mb-4">Filters</h4>

      {/* Prices */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleTogglePrice}
        >
          <h5 className="font-semibold filter-heading">Price</h5>
          {isPriceCollapsed ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isPriceCollapsed && (
          <ul>
            {prices.map((price) => (
              <li key={price} className="flex items-center">
                <input type="checkbox" id={price} className="mr-2" />
                <label htmlFor={price}>{price}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Accessories */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleToggleAccessories}
        >
          <h6 className="font-semibold filter-heading">Accessories</h6>
          {isAccessoriesCollapsed ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isAccessoriesCollapsed && (
          <ul>
            {accessories.map((accessory) => (
              <li key={accessory} className="flex items-center">
                <input type="checkbox" id={accessory} className="mr-2" />
                <label htmlFor={accessory}>{accessory}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Release Year */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleToggleReleaseYear}
        >
          <h6 className="font-semibold filter-heading">Release Year</h6>
          {isReleaseYearsCollapse ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isReleaseYearsCollapse && (
          <ul>
            {releaseYears.map((accessory) => (
              <li key={accessory} className="flex items-center">
                <input type="checkbox" id={accessory} className="mr-2" />
                <label htmlFor={accessory}>{accessory}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Screen Size */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleToggleScreenSizes}
        >
          <h6 className="font-semibold filter-heading">Screen Sizes</h6>
          {isScreenSizesCollapse ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isScreenSizesCollapse && (
          <ul>
            {screenSizes.map((accessory) => (
              <li key={accessory} className="flex items-center">
                <input type="checkbox" id={accessory} className="mr-2" />
                <label htmlFor={accessory}>{accessory}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Storage */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleToggleStorageOptions}
        >
          <h6 className="font-semibold filter-heading">Storage Options</h6>
          {isStorageOptionsCollapse ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isStorageOptionsCollapse && (
          <ul>
            {storageOptions.map((accessory) => (
              <li key={accessory} className="flex items-center">
                <input type="checkbox" id={accessory} className="mr-2" />
                <label htmlFor={accessory}>{accessory}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Color */}
      <div className="mb-4">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={handleToggleColors}
        >
          <h6 className="font-semibold filter-heading">Colors</h6>
          {isColorsCollapse ? (
            <BsChevronDown className="text-xl" />
          ) : (
            <BsChevronUp className="text-xl" />
          )}
        </div>
        {!isColorsCollapse && (
          <ul>
            {colors.map((accessory) => (
              <li key={accessory} className="flex items-center">
                <input type="checkbox" id={accessory} className="mr-2" />
                <label htmlFor={accessory}>{accessory}</label>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
