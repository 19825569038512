import React, { useState } from 'react';
import './Dropdown.css';
import { Link } from 'react-router-dom';

const Dropdown = ({linkName, dropdownList}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="navbar-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link className="navbar-link" href="#">{linkName}</Link>
      {isOpen && (
        <div className="dropdown-content">
          {dropdownList.map((item, index) => (
            <Link to={item.link} key={index}>{item.linkName}</Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
