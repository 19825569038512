import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions, store } from "../../../store";
import edSpareLogo from "../../../assets/images/edspare-logo.png";
import "./Form.css";

const Form = () => {
  const dispatch = useDispatch();

  const history = useNavigate();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await axios
      .post("http://localhost:5000/admin/api/login", {
        email: inputs.email,
        password: inputs.password,
      })
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then(() => dispatch(authActions.login()))
      .then(() => history("/user"));
  };

  console.log(store);

  return (
    <div className="flex flex-col justify-center items-center gap-8 h-full">
      <Link to="/">
        <img src={edSpareLogo} alt="edsparelogo" width={170} />
      </Link>
      <form
        onSubmit={handleSubmit}
        className="bg-white md:w-1/3 w-4/6 form-styles"
      >
        <label className="login-text">Sign in to your account</label>
        <input
          name="email"
          placeholder="Email Address"
          value={inputs.email}
          type="email"
          handleChange={handleChange}
          className="input-box text-sm"
        />
        <input
          name="password"
          placeholder="Password"
          value={inputs.password}
          type="password"
          handleChange={handleChange}
          className="input-box text-sm"
        />
        <button
          type="submit"
          className="px-4 py-2 my-6 bg-[#FFDA87] hover:bg-yellow-200 rounded font-semibold rounded-full"
        >
          Login
        </button>
        <button
          type="submit"
          className="underline text-sm hover:font-semibold duration-200 ease-in ease-out"
        >
          Forgot password?
        </button>
      </form>
    </div>
  );
};

export default Form;
