import React from "react";

const ProductCard = ({ imgUrl }) => {
  return (
    <div className="card-container">
      <div className="flex justify-center">
        <img src={imgUrl} alt="none" width={250} height={200} />
      </div>
      <br />
      <div className="p-2">
        <h4 className="my-1">HIS Extreme Series - XX</h4>
        <p>$150</p>
      </div>
    </div>
  );
};

export default ProductCard;
