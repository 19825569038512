import React from "react";
import Form from "./components/Form";

const Login = () => {
  return (
    <div className="h-screen bg-[#fffbf2]">
      <Form />
    </div>
  );
};

export default Login;
