import React, { useState } from "react";
import "./Faqs.css";
import astronaut from "../../../../assets/images/Asset 2 2.png";
import { AiFillCaretDown, AiOutlineRight } from "react-icons/ai";
import { faqData } from "./FaqData";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="flex flex-col justify-center items-center py-16">
      <br />
      <div className="faq-container flex flex-col justify-center items-center gap-6">
        <div className="flex justify-between">
          <h3>Things you probably wonder about Edspare.</h3>
          <img src={astronaut} alt="astronaut" width={120} className="hidden md:block" />
        </div>
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item cursor-pointer ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => handleClick(index)}
          >
            <div className="question flex justify-between items-center">
              <span>{item.question}</span>
              <AiFillCaretDown className="text-[#F5BF45]" />
            </div>
            {activeIndex === index && (
              <div className="answer">{item.answer}</div>
            )}
          </div>
        ))}
        <button className="faq-button">
            <span>See all</span>
            <AiOutlineRight />
        </button>
      </div>
    </div>
  );
};

export default Faqs;
