import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Products from "../Products/Products";

const ShopLayout = () => {
  return (
    <div className="flex justify-center items-center h-full m-8">
      <div className="md:w-3/4 w-full flex">
        <Sidebar />
        <Products />
      </div>
    </div>
  );
};

export default ShopLayout;
