import React from "react";
import logo1 from '../../../../assets/images/image 3.png'
import logo2 from '../../../../assets/images/image 4 (1).png'
import logo3 from '../../../../assets/images/image 5 (1).png'
import logo4 from '../../../../assets/images/image 5.png'
import logo5 from '../../../../assets/images/image 6.png'
import logo6 from '../../../../assets/images/image 7.png'
import logo7 from '../../../../assets/images/image 8.png'

const logosUrl = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo1]

const Logos = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col justify-center items-center m-4 p-4">
        <p className="uppercase text-center text-lg leading-6 text-[#6C6F93] fold-bold">
          we collaborate with 300+ leading universities and companies
        </p>
        <div className="grid grid-cols-4 md:grid-cols-8 items-center gap-4 my-8">
        {
          logosUrl.map((logo, index) => (
            <img src={logo} key={index} alt="none" width={100} />
          ))
        }
        </div>
        <h1 className="text-center m-2 p-2 w-full md:w-2/3">
          Everything you need for a{" "}
          <span className="bg-[#F5BF45]">happy classroom.</span>
        </h1>
      </div>
    </div>
  );
};

export default Logos;
