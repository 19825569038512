import React from "react";

const BlogCard = ({ imgUrl, blogTitle, blogDescription }) => {
  return (
    <div className="bg-[#fffbf2] border-1 border-[#F3F5FB] shadow shadow-gray-100 m-4">
      <div className="flex justify-center text-center bg-[#FCFBF8] p-4 ">
        <img src={imgUrl} alt="blogImage" className="w-[150px] h-[150px]" />
      </div>
      <h6 className="p-4 font-semibold">{blogTitle}</h6>
      <p className="p-4 text-sm">{blogDescription}</p>
    </div>
  );
};

export default BlogCard;
