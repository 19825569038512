import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FcSms} from 'react-icons/fc'

const TeamCard = ({imgUrl, personName, position}) => {
  return (
    <div className='bg-[#F5F4F7] team-card p-2 m-4 flex justify-center flex-col items-center'>
        <img src={imgUrl} width={250} alt='none' />
        <h4>{personName}</h4>
        <p>{position}</p>
        <div className='flex gap-2 pt-1'>
            <FcSms />
            <BsLinkedin className='text-blue-800' />
        </div>
    </div>
  )
}

export default TeamCard