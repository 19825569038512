import React from "react";
import image from "../../../../assets/images/Frame 10346.png";

const TestimonialCEO = () => {
  return (
    <div>
      <img className="mt-4" src={image} alt="garrytestimonial" />
    </div>
  );
};

export default TestimonialCEO;
