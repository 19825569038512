export const productData = [
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product1.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product2.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product3.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product1.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product2.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product3.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product1.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product2.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
    {
        id: 0,
        productName: "HIS Tablet",
        productImage: require('../../../../assets/images/product3.jpg'),
        productPrice: '£150',
        productDescription: 'Lorem ipsum dolor sit amet consectetur. Aliquam rhoncus ut ornare tortor id tortor aenean arcu.'
    },
]