import React from "react";
import "./Product.css";
import { BsFillCartFill } from 'react-icons/bs'

const ProductCard = ({
  productName,
  productImage,
  productPrice,
  productDescription,
}) => {
  return (
    <div className="flex flex-col product-card">
      <img
        src={productImage}
        alt="productImage"
        width={242}
        height={239}
        className="p-2"
      />
      <div className="flex justify-between items-center p-2">
        <h5 className="product-name">{productName}</h5>
        <p className="product-price">{productPrice}</p>
      </div>
      <div className="p-2">
        <p className="text-sm">{productDescription}</p>
      </div>
      <button className="m-2 bg-[#FFDA87] py-1 px-3 rounded font-semibold text-sm flex justify-center items-center gap-2">
        <BsFillCartFill /> Add To Cart
      </button>
    </div>
  );
};

export default ProductCard;
