import React from "react";
import altImg from "../../../../assets/images/analytics.svg";
import BtnSection from "../../../../components/Buttons/BtnSection";
import ".././LandingPage.css";

const DataAnalytics = () => {
  return (
    <div className="flex justify-center items-center analytics-background">
      <div className="md:flex gap-12 w-3/4 m-4 py-6 md:py-16">
        <div className="w-full md:w-1/2 hidden md:block">
          <img src={altImg} alt="none" width={436} />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="">Seamless Collaboration for Educators</h3>
          <br />
          <p className="w-full md:w-3/4">
            Edspare is dedicated to empowering educators with powerful
            collaboration tools to streamline their teaching process. Our SaaS
            platform enables teachers to create, customize, and share
            interactive lesson plans effortlessly. 
          </p>
          <br />
          <p className="w-full md:w-3/4">
            Real-time collaboration features allow educators to connect with
            their students, provide timely feedback, and foster a collaborative
            learning environment. 
          </p>
          <br />
          <p className="w-full md:w-3/4">
            The platform also provides advanced analytics and insights into
            student progress, allowing teachers to make data-driven decisions
            and tailor their instruction accordingly.
          </p>
          <br />
          <BtnSection text={"Learn More"} />
        </div>
      </div>
    </div>
  );
};

export default DataAnalytics;
