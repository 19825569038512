import React from 'react'
import Layout from '../../layout/Layout'
import { termsData } from './TermsData'

const TermsCondition = () => {
  return (
    <div>
      <Layout>
        <div className='flex flex-col justify-center items-center w-full'>
          <div className='md:w-4/5 w-5/6 mt-8'>
            <h2>Terms & Condition</h2>
            <div>
              {termsData.map((data, index) => (
                <div className='py-4' key={index}>
                  <h6 className='text-xl font-semibold text-yellow-400'>{data.heading}</h6>
                  <br />
                  <p>{data.paragraph}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default TermsCondition