import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="bg-gray-100 w-full p-2 md:h-[600px] md:flex md:justify-center">
      <div className="flex flex-col md:flex-row md:justify-evenly justify-center items-center md:gap-12">
        <div className="md:w-1/4 w-5/6 p-4 text-left">
          <h3>Want to get in touch with us?</h3>
          <br />
          <p>
            We always love hearing from our people, give us a call or drop a
            message and one of our representatives will be in touch with you
            shortly!
          </p>
          <br />
          <button className="contact-btn">
            <span>Drop us a call</span>
            <AiOutlineRight />
          </button>
        </div>
        <div className="md:w-1/4 w-5/6 p-4 flex flex-col justify-center items-center">
          <div className="bg-white p-4 rounded shadow shadow-lg">
            <h4>We'd Love To Hear From You!</h4>
            <br />
            <p>
              Feel free to contact us in our business hours and we will be more
              than obligded to help you.
            </p>
            <br />
            <p>Email: info@edspare.com</p>
            <br />
            <p>Landline: 02086746297</p>
            <br />
            <p>Mobile: +44 7946 087069</p>
            <br />
            <p>Nigeria contact: +234-8023-2396-33</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
