import React from "react";
import BtnSection from "../../../../components/Buttons/BtnSection";
import VerticalSlider from "./VerticalSlider";

const EdspareFeatures = () => {
  return (
    <div className="flex justify-center items-center bg-[#FCFBF8]">
      <div className="flex md:flex-row flex-col w-3/4 mx-4 my-8 md:m-16">
        <div className="w-6/7 md:w-1/2">
          <h3>Everything you need to succeed</h3>
          <br />
          <p className="w-auto md:w-3/4">
            Every event is unique, and Brella has what you need to unlock its
            full potential at every step of the way.
          </p>
          <br />
          <p className="w-auto md:w-3/4">
            ✅ <span className="font-semibold">AI-Driven Personalized Learning:</span> Edspare leverages artificial
            intelligence to deliver personalized learning experiences tailored
            to each student's unique needs and learning style.
          </p>
          <br />
          <p className="w-auto md:w-3/4">
            ✅ <span className="font-semibold">Comprehensive Learning Resources:</span> Edspare offers a vast library
            of educational resources, including interactive lessons, multimedia
            content, and practice exercises.
          </p>
          <br />
          <p className="w-auto md:w-3/4">
            ✅ <span className="font-semibold">Parent Engagement and Communication:</span> The platform enables parents
            to stay informed about their child's progress, receive updates from
            teachers, and actively engage in their child's educational journey.
          </p>
          <br />
          <p className="w-auto md:w-3/4">
            ✅ <span className="font-semibold">Accessible Anytime, Anywhere:</span> Whether in the classroom or at home, students can
            access the platform from desktops, laptops, tablets, or smartphones,
            enabling seamless learning continuity.
          </p>
          <br />
          <BtnSection text={"Learn More"} />
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <VerticalSlider />
        </div>
      </div>
    </div>
  );
};

export default EdspareFeatures;
